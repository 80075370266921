import React from "react"
import {Link} from "gatsby"
// import ThemeChanger from "../components/themeChanger"

export default (props) => (
  <nav className="navigation"> 
    <Link to="/contact">Contact <span role="img" aria-label="love">&#8594;</span>

</Link>
    {/* <ThemeChanger/> */}
  </nav>
  
)